<template>
    <div style="width: 100%; height: 45rem;">
        <b-card no-body style="height: 100%;">
            <b-tabs card style="height: 100%;" content-class="property-tabs-content">
                <b-tab title="주 변수 관리" active>
                    <div class="row m-0">
                        <!-- 주 변수 정보 테이블 -->
                        <div class="col-12 p-0">
                            <MainPropertyTablePanel :detailInfo="detailInfo" />
                        </div>
                        <!-- 데이터 보정 테이블 -->
                        <div class="col-12 p-0">
                            <AdjustDataTablePanel />
                        </div>
                        <div class="col-12 p-0">
                            <MainPropDataTablePanel />
                        </div>
                    </div>
                </b-tab>
                <b-tab title="보조 변수 관리">
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                    <h1>{{ "보조 변수 관리" }}</h1>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import MainPropertyTablePanel from "./MainPropertyTablePanel.vue";
import AdjustDataTablePanel from "./AdjustDataTablePanel.vue";
import MainPropDataTablePanel from "./MainPropDataTablePanel.vue";

export default {
    props: ["detailInfo"],
    components: {
        MainPropertyTablePanel,
        AdjustDataTablePanel,
        MainPropDataTablePanel,
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style scoped></style>

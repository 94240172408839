<template>
    <div class="row m-0 d-flex justify-content-around">
        <div class="col-lg-3 mr-2">
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="ptIdx">{{ "분석 대상 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <div class="input-group">
                        <input
                            class="form-control"
                            type="text"
                            :value="targetPtName && detailInfo.ptIdx ? `${targetPtName} (${detailInfo.ptIdx})` : ''"
                            :placeholder="targetPtName && detailInfo.ptIdx ? '' : '데이터를 선택하세요.'"
                            readonly
                        />
                        <div class="input-group-append">
                            <!-- <button
                                class="btn btn-secondary"
                                type="button"
                                v-b-toggle.pointSideBar
                                @click="showSideBar('target')"
                            >
                                {{ "선택" }}
                            </button> -->
                            <button class="btn btn-secondary" type="button" @click="handleMethodModal('target')">
                                {{ "선택" }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="analysisMethod">{{ "분석 방법 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <select class="form-control" id="analysisMethod" v-model="detailInfo.analysisMethod">
                        <option :value="null">{{ "분석 방법 선택" }}</option>
                        <option
                            v-for="method in $store.state.commonCodes.analysisMethod"
                            :key="method.value"
                            :value="method.value"
                        >
                            {{ method.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="analysisAlgorithm">{{ "알고리즘 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <select class="form-control" id="analysisAlgorithm">
                        <option
                            v-for="type in $store.state.commonCodes.analysisAlgorithm"
                            :key="type.value"
                            :value="type.value"
                        >
                            {{ type.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="dispType">{{ "표시 방법 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <div class="row m-0">
                        <div class="col-6 p-0">
                            <select v-model="detailInfo.dispType" class="form-control" name="dispType">
                                <option :value="null">{{ "표시 방법 선택" }}</option>
                                <option
                                    v-for="type in $store.state.commonCodes.dispType"
                                    :key="type.value"
                                    :value="type.value"
                                >
                                    {{ type.text }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 pr-0">
                            <select v-model="detailInfo.dispUnit" class="form-control" name="dispUnit">
                                <option :value="null">{{ "단위 환산 선택" }}</option>
                                <option
                                    v-for="type in $store.state.commonCodes.dispUnit"
                                    :key="type.value"
                                    :value="type.value"
                                >
                                    {{ type.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2">
            <div class="row m-0">
                <div class="col-12 p-0 mb-2 d-flex justify-content-between align-items-center">
                    <div>
                        <label class="m-0">{{ refDataTitle }}</label>
                    </div>
                    <div class="btn-group">
                        <!-- 버전1 -->
                        <!-- <button
                            class="btn btn-sm btn-warning"
                            type="button"
                            @click="handleMethodModal('member')"
                            :disabled="detailInfo.analysisMethod == 'Trend' || detailInfo.analysisMethod == 'Reltn'"
                        >
                            {{ "추가" }}
                        </button> -->
                        <!-- 버전2 -->
                        <button
                            class="btn btn-sm btn-warning"
                            type="button"
                            @click="handleMethodModal('firstVar')"
                            :disabled="detailInfo.analysisMethod == 'Trend' || detailInfo.analysisMethod == 'Reltn'"
                        >
                            {{ "추가" }}
                        </button>
                        <button
                            class="btn btn-sm btn-danger"
                            type="button"
                            :disabled="detailInfo.analysisMethod == 'Trend' || detailInfo.analysisMethod == 'Reltn'"
                            @click="deleteComponentPoint"
                        >
                            {{ "삭제" }}
                        </button>
                        <!--       @click="showSideBar('member')" -->
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="point-list-ctn">
                        <div
                            v-for="(member, index) in mamberPtList"
                            :key="member.ptIdx"
                            class="form-check"
                            style="margin-bottom: 0.2rem;"
                        >
                            <!-- v-model="type.checked" -->
                            <!-- @change="onEquipTypeChange(type.equipType, type.checked, type.equipTypeName)" -->
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="checkCompPoint[index]"
                                :id="member.ptIdx"
                            />
                            <label class="form-check-label" :for="member.ptIdx">
                                {{ pointList.find((point) => point.ptIdx == member.ptIdx).ptName ?? "-" }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 ml-2">
            <div class="row m-0">
                <div class="col-12 p-0 mb-2 d-flex justify-content-between align-items-center">
                    <div>
                        <label class="m-0">{{ "영향 인자" }}</label>
                    </div>
                    <div class="btn-group">
                        <!-- 버전1 -->
                        <!-- <button
                            class="btn btn-sm btn-warning"
                            type="button"
                            @click="handleMethodModal('factor')"
                            :disabled="detailInfo.analysisMethod == 'Goal'"
                        >
                            {{ "추가" }}
                        </button> -->
                        <!-- 버전2 -->
                        <button
                            class="btn btn-sm btn-warning"
                            type="button"
                            @click="handleMethodModal('secondVar')"
                            :disabled="detailInfo.analysisMethod == 'Goal'"
                        >
                            {{ "추가" }}
                        </button>
                        <button
                            class="btn btn-sm btn-danger"
                            type="button"
                            :disabled="detailInfo.analysisMethod == 'Goal'"
                            @click="deleteReltnPoint"
                        >
                            {{ "삭제" }}
                        </button>
                        <!--   @click="showSideBar('factor')" -->
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="point-list-ctn">
                        <div
                            v-for="(factor, index) in factorPtList"
                            :key="factor.ptIdx"
                            class="form-check"
                            style="margin-bottom: 0.2rem;"
                        >
                            <!-- v-model="type.checked" -->
                            <!-- @change="onEquipTypeChange(type.equipType, type.checked, type.equipTypeName)" -->
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="checkReltnPoint[index]"
                                :id="factor.ptIdx"
                            />
                            <label class="form-check-label" :for="factor.ptIdx">
                                {{ pointList.find((point) => point.ptIdx == factor.ptIdx).ptName ?? "-" }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 custom-col mr-2">
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="timeDsvn">{{ "데이터 유형 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <select class="form-control" id="timeDsvn" v-model="detailInfo.timeDsvn">
                        <option :value="null">{{ "데이터 유형 선택" }}</option>
                        <option v-for="dateType in filterdTimeDsvn" :key="dateType.value" :value="dateType.value">
                            {{ dateType.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="analysisDate">{{ "분석 기간 : " }}</label>
                </div>
                <calendar-component
                    start="true"
                    class="col-9 pr-0"
                    v-model="baseDayTime"
                    :calendarType="detailInfo.timeDsvn"
                    @input="getBaseDayTime"
                    placeholder="기본 기간 선택"
                    :detailInfo="detailInfo"
                    @range="changeRange"
                />
            </div>
            <!--    :disabledCalendar="!isEditMode" -->
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="compareType">{{ "비교 방법 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <select
                        class="form-control"
                        id="compareType"
                        :class="{ 'disabled-select': !detailInfo.timeDsvn }"
                        v-model="detailInfo.compareType"
                        :disabled="!detailInfo.timeDsvn || detailInfo.analysisMethod === 'Goal'"
                    >
                        <option :value="null">{{ "비교 없음" }}</option>
                        <option v-for="comp in compareTypeArr" :key="comp.value" :value="comp.value">
                            {{ comp.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="comparePeriod">{{ "비교 기간 : " }}</label>
                </div>
                <!-- <date-picker class="col-9 pr-0" range valueType="YYYY-MM-DD" lang="ko"
                    placeholder="비교 기간 선택"></date-picker> -->
                <calendar-component
                    end="true"
                    class="col-9 pr-0"
                    v-model="compareDayTime"
                    :calendarType="detailInfo.timeDsvn"
                    :baseDayTime="baseDayTime"
                    :compareType="detailInfo.compareType"
                    @input="getCompareDayTime"
                    placeholder="비교 기간 선택"
                    :detailInfo="detailInfo"
                />
            </div>
        </div>

        <div class="settingButtonGroup">
            <div>
                <!-- 첫 번째 행 -->
                <div class="row">
                    <!-- 아이콘 -->
                    <div class="col-md-6">
                        <div class="icon-ctn" @click="toggleIconSelect">
                            <i :class="!isEmpty(detailInfo.icon) ? detailInfo.icon : 'fa fa-times'"></i>
                            <icon-select
                                v-if="isIconSelect"
                                :current="''"
                                :hideText="true"
                                :positionLeft="'-5rem'"
                                @on-change="onChangeIcon"
                            />
                        </div>
                    </div>
                    <!-- 분석 실행 -->
                    <div class="col-md-6">
                        <button
                            type="button"
                            class="analysisButton btn btn-primary btn-square"
                            @click="getAnalResultData"
                        >
                            <span>{{ "분석" }}</span>
                            <span>{{ "준비" }}</span>
                        </button>
                    </div>
                </div>

                <!-- 두 번째 행 -->
                <div class="row mt-2">
                    <!-- 보고서 저장 -->
                    <div class="col-md-6">
                        <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary w-100 "
                            :disabled="isEmpty(resultData)"
                            @click="exportPDF"
                        >
                            <span>{{ "보고서" }}<br />{{ "저장" }}</span>
                        </button>
                    </div>
                    <!-- 데이터 조회 -->
                    <div class="col-md-6">
                        <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary w-100 h-100"
                            @click="() => $refs['property-management-modal'].show()"
                        >
                            <span>{{ "변수 관리" }}</span>
                        </button>
                    </div>
                </div>

                <!-- 세 번째 행 -->
                <div class="row mt-2">
                    <!-- 설정 관리 -->
                    <div class="col-md-4">
                        <button
                            class="btn btn-sm btn-outline-secondary w-100"
                            type="button"
                            @click="() => $refs['setting-mgmt'].show()"
                        >
                            <span>{{ "설정" }}</span>
                        </button>
                    </div>
                    <!-- 노출 설정 -->
                    <div class="col-md-4">
                        <button
                            class="btn btn-sm btn-outline-secondary w-100"
                            type="button"
                            :disabled="isEmpty(detailInfo.modelIdx)"
                            @click="() => $refs['set-menu'].show()"
                        >
                            <span>{{ "노출" }}</span>
                        </button>
                    </div>
                    <!-- 기록 관리 -->
                    <div class="col-md-4">
                        <button
                            class="btn btn-sm btn-outline-secondary w-100"
                            type="button"
                            :disabled="isEmpty(detailInfo.modelIdx)"
                            @click="showAnalysisHistory"
                        >
                            <span>{{ "기록" }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- 타겟설정(관제점으로 선택, 사용자 지정, 분석목록에서 선택) 모달 -->
        <b-modal ref="modalTarget" centered size="lg" :title="methodModalTitle" id="modal-target">
            <div class="targetContainer">
                <div class="methodList">
                    <ul class="methodListUl">
                        <li class="methodListLi" @click="handleSeveralTargetMethod('exist')">
                            분석 모델에서 찾기
                            <i class="fas fa-chevron-right rightArrowI" />
                        </li>
                        <li class="methodListLi" @click="handleSeveralTargetMethod('user')">
                            사용자 변수 추가 <i class="fas fa-chevron-right rightArrowI" />
                        </li>
                        <li class="methodListLi" @click="handleSeveralTargetMethod('point')">관제점목록에서 선택</li>
                    </ul>
                </div>
                <div class="detailMethod">
                    <div v-if="severalTargetMethod === 'exist'" class="variablesContainer">
                        <div class="withoutButton">
                            <div>
                                <div class="mb-1">
                                    분석 모델
                                </div>
                                <b-form-select
                                    v-model="selectedExist"
                                    :options="[{ value: null, text: '분석모델을 선택하세요' }, ...modelOptions]"
                                    @change="fetchAnalysisModel"
                                ></b-form-select>
                            </div>
                            <div class="mt-3">
                                <b-table :items="items" :fields="fields" responsive="sm" hover>
                                    <template #cell(checkbox)="row">
                                        <b-form-checkbox v-model="row.item.selected" />
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary">선택하기</button>
                    </div>
                    <div v-else-if="severalTargetMethod === 'user'" class="variablesContainer">
                        <div class="withoutButton">
                            <div class="userVariablesRow">
                                <div class="userVariablesTitle">변수명</div>
                                <input class="form-control" placeholder="변수명을 입력하세요" />
                            </div>
                            <div class="userVariablesRow">
                                <div class="userVariablesTitle">단위유형</div>
                                <b-form-select
                                    v-model="addUserUnitType"
                                    :options="[{ value: null, text: '단위유형을 선택하세요' }, ...unitTypeOptions]"
                                    placeholder="단위유형을 선택하세요"
                                ></b-form-select>
                            </div>
                            <div class="userVariablesRow">
                                <div class="userVariablesTitle">단위</div>
                                <b-form-select
                                    v-model="addUserUnit"
                                    :options="[{ value: null, text: '단위를 선택하세요' }, ...unitOptions]"
                                    placeholder="단위를 선택하세요"
                                ></b-form-select>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary">저장하기</button>
                    </div>
                    <div v-else-if="severalTargetMethod === 'point'" class="ifPointSelectContainer">
                        <b-spinner
                            v-if="isSidebarVisible"
                            style="width: 3rem; height: 3rem;"
                            label="Large Spinner"
                        ></b-spinner>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <div class="d-flex justify-content-end">
                    <b-button variant="secondary" @click="closeTargetMethodModal">cancel</b-button>
                </div>
            </template>
        </b-modal>
        <!-- 관제점 조회 사이드바 -->
        <b-sidebar
            v-model="isSidebarVisible"
            ref="PointSideBar"
            id="pointSideBar"
            bg-variant="white"
            backdrop-variant="secondary"
            width="45vw"
            @hidden="closeSidebar"
            no-header
            shadow
            right
            backdrop
            class="pointSearchSidebar"
        >
            <div style="padding: 1rem; height: 100%">
                <template v-if="currentSidebarType === 'target'">
                    <SearchComponent @close="closeSidebar" @select-point="setPointInfoData" :mode="'single'" />
                </template>
                <!-- 버전1 -->
                <!-- <template v-else-if="currentSidebarType === 'member'">
                    <SearchComponent @close="closeSidebar" @select-point="setPointInfoData" :mode="'multi'" />
                </template> -->
                <!-- 버전2 -->
                <template v-else-if="currentSidebarType === 'firstVar'">
                    <SearchComponent @close="closeSidebar" @select-point="setPointInfoData" :mode="'multi'" />
                </template>
                <!-- 버전1 -->
                <!-- <template v-else-if="currentSidebarType === 'factor'">
                    <SearchComponent @close="closeSidebar" @select-point="setPointInfoData" :mode="'multi'" />
                </template> -->
                <!-- 버전2 -->
                <template v-else-if="currentSidebarType === 'secondVar'">
                    <SearchComponent @close="closeSidebar" @select-point="setPointInfoData" :mode="'multi'" />
                </template>
                <template v-else>
                    <div>잘못된 사이드바 타입입니다.</div>
                </template>
            </div>
        </b-sidebar>
        <!-- 설정 관리 모달 -->
        <b-modal ref="setting-mgmt" centered @hidden="closeSettingMgmtModal">
            <template #modal-header>
                <h5 class="m-0">{{ "설정 관리" }}</h5>
            </template>
            <SettingMgmtModalPanel ref="SettingMgmtModalPanel" :detailInfo="detailInfo" :analysisList="analysisList" />
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        class="btn btn-primary"
                        type="button"
                        style="flex: 1; margin-right: 0.5rem"
                        @click="getSettingMgmt"
                    >
                        {{ "저장" }}
                    </button>
                    <button
                        class="btn btn-secondary"
                        type="button"
                        style="flex: 1; margin-left: 0.5rem"
                        @click="closeSettingMgmtModal"
                    >
                        {{ "취소" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <!-- 노출 설정 모달 -->
        <b-modal ref="set-menu" centered @hidden="() => $refs['set-menu'].hide()">
            <template #modal-header>
                <h5 class="m-0">{{ "노출 설정" }}</h5>
            </template>
            <SetMenuModalPanel ref="SetMenuModalPanel" :detailInfo="detailInfo" :mode="mode" />
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        class="btn btn-primary"
                        type="button"
                        style="flex: 1; margin-right: 0.5rem"
                        @click="saveDisplayMenu"
                    >
                        {{ "적용" }}
                    </button>
                    <button
                        class="btn btn-secondary"
                        type="button"
                        style="flex: 1; margin-left: 0.5rem"
                        @click="() => $refs['set-menu'].hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <!-- 분석 기록 관리 모달 -->
        <b-modal ref="analysis-history" size="lg" centered @hidden="() => $refs['analysis-history'].hide()">
            <template #modal-header>
                <h5 class="m-0">{{ "분석 기록 관리" }}</h5>
            </template>
            <HistoryMgmtModalPanel
                :detailInfo="detailInfo"
                :resultData="resultData"
                @show-history="showAnalysisHistory"
            />
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        class="btn btn-secondary"
                        type="button"
                        style="flex: 1"
                        @click="() => $refs['analysis-history'].hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <!-- 분석 결과 데이터 보기 -->
        <!-- <b-modal
            ref="analysis-data"
            body-class="analysis-data-modal-body"
            size="xl"
            centered
            @hidden="() => $refs['analysis-data'].hide()"
        >
            <template #modal-header>
                <h5 class="m-0">{{ "데이터 보기" }}</h5>
            </template>
            <div style="height: 40rem; overflow-y: auto;">
                <div style="padding: 1rem;">
                    <AnalysisDataModalPanel :resultData="resultData" />
                </div>
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        class="btn btn-secondary"
                        type="button"
                        style="flex: 1;"
                        @click="() => $refs['analysis-data'].hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal> -->
        <!-- 변수 관리 모달 -->
        <b-modal
            id="property-management"
            body-class="property-management-modal-body"
            ref="property-management-modal"
            centered
            @hidden="() => $refs['property-management-modal'].hide()"
        >
            <template #modal-header>
                <h5 class="m-0">{{ "변수 관리" }}</h5>
            </template>
            <div style="width: 100%; padding: 1rem !important;">
                <PropertyMgmtModalPanel :detailInfo="detailInfo" />
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        class="btn btn-secondary"
                        style="flex: 1;"
                        @click="$refs['property-management-modal'].hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import SearchComponent from "@src/views/component/v2.1/PointSearch/Search.vue";
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
import SettingMgmtModalPanel from "./SettingMgmtModalPanel.vue";
import SetMenuModalPanel from "./SetMenuModalPanel.vue";
import HistoryMgmtModalPanel from "./HistoryMgmtModalPanel.vue";
// 데이터 보기 모달 패널 삭제 예정..?
// import AnalysisDataModalPanel from "./AnalysisDataModalPanel.vue";
import CalendarComponent from "@src/views/playground/CalendarComponent.vue";
import backEndApi from "@src/api/backEndApi";

import PropertyMgmtModalPanel from "./PropertyMgmtModalPanel.vue";

// import CustomSwitch from "@src/components/switch/CustomSwitch.vue";

// import * as XLSX from "xlsx";

import { mapState } from "vuex";

export default {
    props: ["detailInfo", "analysisList", "resultData"],
    components: {
        SearchComponent,
        // DatePicker,
        SettingMgmtModalPanel,
        SetMenuModalPanel,
        HistoryMgmtModalPanel,
        // 데이터 보기 모달 패널 삭제 예정..?
        // AnalysisDataModalPanel,
        CalendarComponent,
        // CustomSwitch,
        PropertyMgmtModalPanel,
    },
    data() {
        return {
            methodType: "",
            methodModal: false,
            methodModalTitle: "",
            severalTargetMethod: "",
            sideBarBtnType: null,
            detailModel: null,
            isLoadingFetchModel: false,
            baseDayTime: [],
            compareDayTime: [],
            rangeBooleanlocal: false,

            isSidebarVisible: false, // 현재 사이드바 표시 여부
            currentSidebarType: null,

            compareTypeArr: [],
            mode: "new",
            isIconSelect: false,

            checkCompPoint: [],
            checkReltnPoint: [],
            filterdTimeDsvn: this.timeDsvn,
            sideBarMode: "single",
            fixPointListSidebarOptions: false,
            targetModalState: null,

            //분석모델에서 찾기 테이블 임시 데이터입니다.
            fields: [
                { key: "checkbox", label: "", sortable: false },
                { key: "변수명", label: "변수명" },
                { key: "유형", label: "유형" },
                { key: "단위", label: "단위" },
            ],
            items: [
                { 변수명: "변....수명1", 유형: "사용자정의", 단위: "kWh", selected: false },
                { 변수명: "변수....명2", 유형: "관제점", 단위: "kWh", selected: false },
                { 변수명: "변수명....3", 유형: "관제점", 단위: "kWh", selected: false },
            ],
            //임시데이터입니다.
            selectedExist: null,
            addUserUnitType: null,
            unitTypeOptions: [
                { value: "gas", text: "가스" },
                { value: "elec", text: "찌릿" },
            ],
            addUserUnit: null,
            unitOptions: [
                { value: "kWh", text: "kWh" },
                { value: "nm3", text: "nm3" },
            ],
        };
    },
    computed: {
        ...mapState({
            pointList: (state) => state.pointList,
            timeDsvnStore: (state) => state.commonCodes.timeDsvn,
        }),
        modelOptions() {
            return this.analysisList.map((item) => {
                return { value: item.modelIdx, text: item.modelName };
            });
        },
        targetPtName() {
            let find = this.pointList.find((point) => point.ptIdx == this.detailInfo.ptIdx);
            return !this.isEmpty(find) ? find.ptName : null;
        },
        mamberPtList() {
            // 버전1
            // return this.detailInfo.ptMaps.filter((item) => item.ptReltnType !== "Factor");
            // 버전2
            return this.detailInfo.variables?.filter(
                (item) => item.roleType !== "Influence" && item.roleType !== "Main"
            );
        },
        factorPtList() {
            //버전1
            // return this.detailInfo.ptMaps.filter((item) => item.ptReltnType == "Factor");
            // 버전2
            return this.detailInfo.variables?.filter((item) => item.roleType == "Influence");
        },

        // get() {
        //     return this.mode == "edit" || this.mode == "new";
        // },
        // set(value) {
        //     if (value) {
        //         if (this.mode !== "new") this.mode = "edit";
        //     } else this.mode = "view";
        //     // this.mode = value ? "edit" : "view";
        // },
        isEditMode: {
            get() {
                return this.mode === "edit" || this.mode === "new";
            },
            set(value) {
                if (value) {
                    if (this.mode !== "new") this.mode = "edit";
                } else {
                    this.mode = "new"; // 잘못된 경우 기본값으로 "new" 설정
                }
            },
        },
        isNewMode() {
            return this.mode == "new";
        },
        refDataTitle() {
            let title = "";
            if (this.detailInfo.analysisMethod == "Trend") title = "참조 데이터";
            else if (this.detailInfo.analysisMethod == "Diff") title = "비교 대상";
            else if (this.detailInfo.analysisMethod == "Comp") title = "구성 요소";
            else if (this.detailInfo.analysisMethod == "Reltn") title = "참조 데이터";
            else if (this.detailInfo.analysisMethod == "Goal") title = "운영 목표";
            else title = "참조 데이터";
            return title;
        },
    },
    watch: {
        "detailInfo.timeDsvn": {
            deep: true,
            handler(type) {
                this.customCompareType(type);
                // if (this.isEditMode) this.detailInfo.compareType = null;
                if (type === null) {
                    this.baseDayTime = [];
                    this.compareDayTime = [];
                    this.detailInfo.compareType = null;
                }
                if (type === "Year") this.detailInfo.compareType = null;
            },
        },
        "detailInfo.compareType": {
            deep: true,
            handler(compareType) {
                if (compareType === null) {
                    // this.compareDayTime = [];
                    this.detailInfo.fromDateComp = null;
                    this.detailInfo.toDateComp = null;
                }
            },
        },
        "detailInfo.analysisMethod": {
            deep: true,
            handler(method) {
                if (method) {
                    this.switchVariables(method);
                }
                if (method === "Goal") {
                    this.detailInfo.compareType = null;
                    this.detailInfo.toDateComp = null;
                    this.filterdTimeDsvn = this.timeDsvnStore.filter((item) => {
                        return !["QUARTER", "HOUR", "DAY"].includes(item.value);
                    });

                    // this.detailInfo.timeDsvn = null;
                } else {
                    this.filterdTimeDsvn = this.timeDsvnStore;
                }
            },
        },
        sideBarBtnType(newVal) {
            if (newVal === "member" && this.detailInfo.analysisMethod === "Goal") {
                this.fixPointListSidebarOptions = true;
            } else {
                this.fixPointListSidebarOptions = false;
            }
        },
    },
    created() {},
    mounted() {
        this.customCompareType();
    },
    methods: {
        switchVariables(method) {
            if (method === "Trend") {
                this.detailInfo.variables = this.detailInfo.variables.filter(
                    (item) => item.roleType === "Influence" || item.roleType === "Main"
                );
            } else if (method === "Diff") {
                this.detailInfo.variables.forEach((item) => {
                    if (item.roleType !== "Influence" && item.roleType !== "Main") {
                        item.roleType = "Compare";
                    }
                });
            } else if (method === "Comp") {
                this.detailInfo.variables.forEach((item) => {
                    if (item.roleType !== "Influence" && item.roleType !== "Main") {
                        item.roleType = "Component";
                    }
                });
            } else if (method === "Reltn") {
                this.detailInfo.variables = this.detailInfo.variables.filter(
                    (item) => item.roleType === "Influence" || item.roleType === "Main"
                );
            } else if (method === "Goal") {
                this.detailInfo.variables = this.detailInfo.variables.filter(
                    (item) => item.roleType === "Goal" || item.roleType === "Main"
                );
            } else if (method === "MNV") {
                this.detailInfo.variables = this.detailInfo.variables.filter(
                    (item) => item.roleType === "Independ" || item.roleType === "Main"
                );
            }
        },
        makeVarFormat(method) {
            if (method === "Trend") {
                return [null, "Influence"];
            } else if (method === "Diff") {
                return ["Compare", "Influence"];
            } else if (method === "Comp") {
                return ["Component", "Influence"];
            } else if (method === "Reltn") {
                return ["Influence", null];
            } else if (method === "Goal") {
                return ["Goal", "Predict"];
            } else if (method === "MNV") {
                return ["Independ", null];
            }
        },
        async fetchAnalysisModel(modelIdx) {
            if (modelIdx == null) return;
            this.isLoadingFetchModel = true;
            try {
                let result = await backEndApi.analysisModel.getAnalysisModel(modelIdx);
                if (result.status == 200) {
                    this.detailModel = result.data;
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoadingFetchModel = false;
            }
        },
        // 버전1
        // handleMethodModal(type) {
        //     if (type === "target") {
        //         this.methodModalTitle = "분석 대상";
        //     } else if (type === "member") {
        //         this.methodModalTitle = "구성 성분";
        //     } else if (type === "factor") {
        //         this.methodModalTitle = "영향 인자";
        //     }
        //     this.methodType = type;
        //     this.$refs.modalTarget.show();
        // },
        // 버전2
        handleMethodModal(type) {
            if (type !== "target" && !this.detailInfo.analysisMethod) {
                console.log("target ", type);
                this.alertNoti("분석 방법을 선택하세요");
                return;
            }
            if (type === "target") {
                this.methodModalTitle = "분석 대상";
            } else if (type === "firstVar") {
                this.methodModalTitle = "보조변수1";
            } else if (type === "secondVar") {
                this.methodModalTitle = "보조변수2";
            }
            this.methodType = type;
            this.$refs.modalTarget.show();
        },
        closeTargetMethodModal() {
            this.$refs.modalTarget.hide();
        },
        handleSeveralTargetMethod(method) {
            this.severalTargetMethod = method;
            if (method === "point") {
                this.showSideBar(this.methodType);
            }
        },
        showSideBar(type) {
            this.currentSidebarType = type;
            this.sideBarMode = type === "target" ? "single" : "multi";
            this.isSidebarVisible = true;
        },
        closeSidebar() {
            this.isSidebarVisible = false;
            this.currentSidebarType = null;
        },
        changeRange(val) {
            this.rangeBooleanlocal = val;
        },
        customCompareType(type) {
            const compareTypeArr = this.$store.state.commonCodes.compareType;
            const filterConditions = {
                YEAR: (value) => value === "User",
                MONTH: (value) => ["LastYear", "3YearAvg", "5YearAvg", "10YearAvg", "User"].includes(value),
                DAY: (value) =>
                    ["LastYear", "LastMonth", "LastWeek", "3YearAvg", "5YearAvg", "10YearAvg", "User"].includes(value),
                HOUR: (value) =>
                    [
                        "LastDay",
                        "LastYear",
                        "LastMonth",
                        "LastWeek",
                        "3DayAvg",
                        "7DayAvg",
                        "30DayAvg",
                        "3MonthAvg",
                        "User",
                    ].includes(value),
                QUARTER: (value) =>
                    [
                        "LastDay",
                        "LastYear",
                        "LastMonth",
                        "LastWeek",
                        "3DayAvg",
                        "7DayAvg",
                        "30DayAvg",
                        "3MonthAvg",
                        "User",
                    ].includes(value),
            };

            const customCompareTypeArr = compareTypeArr.filter((item) => {
                const condition = filterConditions[type];
                return condition ? condition(item.value) : true;
            });
            this.compareTypeArr = customCompareTypeArr;
        },

        exportPDF() {
            this.$emit("exportToPDF");
        },
        getAnalResultData() {
            // this.$emit("changeAnalysisWay", this.detailInfo.analysisMethod);
            // this.$emit("analysisRun");
            this.$emit("result");
        },
        // async setPointInfoData(info) {
        //     if (this.sideBarBtnType == "target") {
        //         this.detailInfo.ptIdx = info.ptIdx;
        //     } else if (this.sideBarBtnType == "member") {
        //         this.detailInfo.ptMaps = [
        //             ...this.detailInfo.ptMaps,
        //             ...info.map((element) => ({ ptIdx: element.ptIdx, ptReltnType: "Member" })),
        //         ];
        //     } else if (this.sideBarBtnType == "factor") {
        //         this.detailInfo.ptMaps = [
        //             ...this.detailInfo.ptMaps,
        //             ...info.map((element) => ({ ptIdx: element.ptIdx, ptReltnType: "Factor" })),
        //         ];
        //     } else return;
        //     await this.closeSidebar();
        // },
        // 버전1
        // setPointInfoData(info) {
        //     if (this.currentSidebarType === "target") {
        //         this.detailInfo.ptIdx = info.ptIdx;
        //     } else if (this.currentSidebarType === "member") {
        //         this.addToMember(info);
        //     } else if (this.currentSidebarType === "factor") {
        //         this.addToFactor(info);
        //     }
        //     this.closeSidebar();
        //     this.closeTargetMethodModal();
        // },
        //    버전1
        // addToMember(info) {
        //     const newEntries = info.map((element) => ({
        //         ptIdx: element.ptIdx,
        //         ptReltnType: "Member",
        //     }));

        //     const existingEntries = new Set(this.detailInfo.ptMaps.map((map) => `${map.ptIdx}-${map.ptReltnType}`));
        //     const uniqueEntries = newEntries.filter(
        //         (entry) => !existingEntries.has(`${entry.ptIdx}-${entry.ptReltnType}`)
        //     );

        //     this.detailInfo.ptMaps = [...this.detailInfo.ptMaps, ...uniqueEntries];
        // },
        // 버전1
        // addToFactor(info) {
        //     const newEntries = info.map((element) => {
        //         console.log("element in addToFactor func", element);
        //         return {
        //             ptIdx: element.ptIdx,
        //             ptReltnType: "Factor",
        //         };
        //     });

        //     const existingEntries = new Set(this.detailInfo.ptMaps.map((map) => `${map.ptIdx}-${map.ptReltnType}`));
        //     const uniqueEntries = newEntries.filter(
        //         (entry) => !existingEntries.has(`${entry.ptIdx}-${entry.ptReltnType}`)
        //     );

        //     this.detailInfo.ptMaps = [...this.detailInfo.ptMaps, ...uniqueEntries];
        // },
        // 버전2
        setPointInfoData(info) {
            if (this.currentSidebarType === "target") {
                this.addToTarget(info);
            } else if (this.currentSidebarType === "firstVar") {
                this.addToFirstVar(info);
            } else if (this.currentSidebarType === "secondVar") {
                this.addToSecondVar(info);
            }
            this.closeSidebar();
            this.closeTargetMethodModal();
        },
        // 버전2
        addToTarget(info) {
            this.detailInfo.ptIdx = info.ptIdx;
            const targetObj = { name: info.ptName, ptIdx: info.ptIdx, roleType: "Main", srcType: "Point" };
            this.detailInfo.variables = [...this.detailInfo.variables, targetObj];
        },
        // 버전2
        addToFirstVar(info) {
            const newEntries = info.map((element) => ({
                name: element.ptName,
                ptIdx: element.ptIdx,
                roleType: this.makeVarFormat(this.detailInfo.analysisMethod)[0],
                srcType: element.dataSourceCode === "Meas" ? "Point" : "User",
            }));

            const existingEntries = new Set(this.detailInfo.variables?.map((map) => `${map.ptIdx}-${map.roleType}`));
            const uniqueEntries = newEntries.filter(
                (entry) => !existingEntries.has(`${entry.ptIdx}-${entry.roleType}`)
            );

            this.detailInfo.variables = [...this.detailInfo?.variables, ...uniqueEntries];
        },
        // 버전2
        addToSecondVar(info) {
            const newEntries = info.map((element) => ({
                name: element.ptName,
                ptIdx: element.ptIdx,
                roleType: this.makeVarFormat(this.detailInfo.analysisMethod)[1],
                srcType: element.dataSourceCode === "Meas" ? "Point" : "User",
            }));

            const existingEntries = new Set(this.detailInfo.variables?.map((map) => `${map.ptIdx}-${map.roleType}`));
            const uniqueEntries = newEntries.filter(
                (entry) => !existingEntries.has(`${entry.ptIdx}-${entry.roleType}`)
            );

            this.detailInfo.variables = [...this.detailInfo?.variables, ...uniqueEntries];
        },
        setDayTime(detail) {
            this.rangeBooleanlocal =
                ["MONTH", "DAY", "YEAR"].includes(detail.timeDsvn) && detail.analysisMethod !== "Goal";

            if (detail.fromDate && detail.toDate && this.rangeBooleanlocal) {
                this.baseDayTime = [detail.fromDate, detail.toDate];
            } else if (detail.fromDate) {
                this.baseDayTime = detail.fromDate;
            } else {
                this.baseDayTime = null;
            }

            if (detail.fromDateComp && detail.toDateComp && this.rangeBooleanlocal) {
                this.compareDayTime = [detail.fromDateComp, detail.toDateComp];
            } else if (detail.fromDateComp) {
                this.compareDayTime = detail.fromDateComp;
            } else {
                this.compareDayTime = null;
            }
        },
        getBaseDayTime(baseDay) {
            if (!this.isEmpty(baseDay) && baseDay.length === 2) {
                this.detailInfo.fromDate = baseDay[0];
                this.detailInfo.toDate = baseDay[1];
            } else if (typeof baseDay === "string") {
                this.detailInfo.fromDate = baseDay;
                this.detailInfo.toDate = baseDay;
            } else return;
        },
        getCompareDayTime(compDay) {
            if (!this.isEmpty(compDay) && compDay.length === 2) {
                this.detailInfo.fromDateComp = compDay[0];
                this.detailInfo.toDateComp = compDay[1];
            } else if (typeof compDay === "string") {
                this.detailInfo.fromDateComp = compDay;
                this.detailInfo.toDateComp = compDay;
            } else return;
        },
        setEditMode() {
            this.mode = "edit";
        },
        setNewMode() {
            this.mode = "new";
        },
        // setViewMode() {
        //     this.mode = "view";
        // },
        toggleIconSelect() {
            this.isIconSelect = !this.isIconSelect;
        },
        onChangeIcon(value) {
            if (value) {
                this.detailInfo.icon = value.icon;
                this.toggleIconSelect();
            } else return;
        },
        async getSettingMgmt() {
            const setting = this.$refs["SettingMgmtModalPanel"].settingInfo;
            if (!this.isEmpty(setting)) {
                this.detailInfo.modelName = setting.modelName;
                this.detailInfo.category = setting.category;
                this.detailInfo.enabled = setting.enabled;
                this.detailInfo.description = setting.description;
                await this.$emit("save-modal");
                await this.$refs["setting-mgmt"].hide();
            }
        },
        closeSettingMgmtModal() {
            this.$refs["setting-mgmt"].hide();
        },
        // clearAnalysisResult() {
        //     this.$emit("clearResult");
        // },
        async deleteComponentPoint() {
            if (this.checkCompPoint.length === 0 || this.checkCompPoint.every((item) => item === false)) {
                this.alertNoti("선택된 항목이 없습니다.");
                return;
            }
            const confirm = await this.alertConfirmWarning("선택한 항목을 삭제하시겠습니까?");
            if (!confirm.value) return;

            // 체크된 항목의 ptIdx를 저장
            const foundMemberPt = this.mamberPtList
                .filter((_, index) => this.checkCompPoint[index]) // 체크된 항목만 필터링
                .map((member) => member.ptIdx); // ptIdx만 추출
            // detailInfo.ptMaps에서 foundMemberPt에 포함되지 않은 항목만 남김
            this.detailInfo.variables = this.detailInfo.variables.filter(
                (ptMap) => !foundMemberPt.includes(ptMap.ptIdx)
            );
            // checkCompPoint 배열 초기화
            this.checkCompPoint = [];
        },
        async deleteReltnPoint() {
            if (this.checkReltnPoint.length === 0 || this.checkReltnPoint.every((item) => item === false)) {
                this.alertNoti("선택한 항목이 없습니다.");
                return;
            }

            const confirm = await this.alertConfirmWarning("선택한 항목을 삭제하시겠습니까?");
            if (!confirm.value) return;

            const foundFactorPt = this.factorPtList
                .filter((_, index) => this.checkReltnPoint[index])
                .map((factor) => factor.ptIdx);
            this.detailInfo.variables = this.detailInfo.variables.filter(
                (ptMap) => !foundFactorPt.includes(ptMap.ptIdx)
            );
            this.checkReltnPoint = [];
        },
        // 나중에 사용 가능성이 있어서 주석
        // resultDataDownLoad() {
        //     if (this.isEmpty(this.resultData)) {
        //         this.alertWarning("분석 실행 후 다운로드 하세요.");
        //         return;
        //     } else {
        //         const values = this.replaceKeysWithPtName(this.resultData.values, this.resultData.headers);
        //         const keys = Object.keys(values).filter((key) => key !== "regDt");
        //         // 행 데이터 생성
        //         const rows = values.regDt.map((date, index) => {
        //             const row = { 일자: date }; // regDt 추가
        //             keys.forEach((key) => {
        //                 row[key] = Array.isArray(values[key]) ? values[key][index] ?? "" : "";
        //             });
        //             return row;
        //         });
        //         if (!this.isEmpty(rows)) {
        //             // 열 순서를 명시적으로 설정
        //             const headers = ["일자", ...keys]; // regDt를 첫 번째로 설정
        //             const aoaData = [
        //                 headers, // 첫 번째 행: 헤더
        //                 ...rows.map((row) => headers.map((header) => row[header] ?? "")), // 데이터 행
        //             ];
        //             const workSheet = XLSX.utils.aoa_to_sheet(aoaData); // 워크시트 생성

        //             headers.forEach((header, colIndex) => {
        //                 const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex }); // 헤더 셀 주소
        //                 const cell = workSheet[cellAddress];
        //                 if (cell) {
        //                     cell.s = {
        //                         // 스타일 설정
        //                         font: { bold: true, color: { rgb: "FFFFFF" } }, // 글꼴: 굵게, 흰색
        //                         fill: { patternType: "solid", fgColor: { rgb: "4F81BD" } }, // 배경색: 파란색
        //                         alignment: { horizontal: "center", vertical: "center" }, // 정렬: 가운데
        //                     };
        //                 }
        //             });

        //             const workBook = XLSX.utils.book_new(); // 워크북 생성

        //             // 엑셀명
        //             const target = this.resultData.headers.find((header) => header.ptIdx == this.detailInfo.ptIdx);
        //             let excelName = target ? `${target.ptName} 데이터.xlsx` : "analysis result 데이터.xlsx";
        //             if (excelName.includes("/")) excelName = excelName.replace(/\//g, "_");

        //             // 엑셀 파일 생성 및 다운로드
        //             XLSX.utils.book_append_sheet(workBook, workSheet, excelName);
        //             XLSX.writeFile(workBook, excelName);
        //         }
        //     }
        // },
        // replaceKeysWithPtName(values, headers) {
        //     // ptIdx와 ptName 매핑 객체 생성
        //     const ptMap = headers.reduce((map, item) => {
        //         // ptIdx를 키로, ${item.ptAddr} (${item.ptIdx})을 값으로 설정
        //         map[item.ptIdx] = `${item.ptAddr} (${item.ptIdx})`;
        //         return map;
        //     }, {});
        //     // 새로운 values 객체 생성
        //     const updatedValues = Object.entries(values).reduce((result, [key, value]) => {
        //         if (key === "regDt") {
        //             result[key] = value; // regDt는 그대로 유지
        //         } else {
        //             const newKey = ptMap[key] || key;
        //             result[newKey] = value;
        //         }
        //         return result;
        //     }, {});
        //     return updatedValues;
        // },
        showAnalysisHistory() {
            this.$refs["analysis-history"].show();
        },
        showSettingMgmtModal() {
            this.$refs["setting-mgmt"].show();
        },
        // 노출 설정 모달에 분석 정보 저장 API 적용 by woonsik
        async saveDisplayMenu() {
            const publish = this.$refs.SetMenuModalPanel.settingPublish;
            if (!this.isEmpty(publish)) {
                this.detailInfo.publish = publish;
                let result = await this.$emit("save-modal");
                if (result) await this.$refs["set-menu"].hide();
            }
        },
    },
};
</script>

<style scoped>
.ifPointSelectContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.userVariablesTitle {
    margin-bottom: 7px;
}
.userVariablesRow {
    margin: 10px 0;
}
.variablesContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.withoutButton {
}
.pointSearchSidebar {
    z-index: 1050;
}
.rightArrowI {
    color: #bdbdbd;
    font-size: 10px;
}
.methodListUl {
    list-style: none;
    margin: 0;
    padding: 0;
}
.methodListLi {
    pointer-events: auto;
    height: 2.2rem;
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px;
    margin: 3px 7px;
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.methodListLi:hover {
    background-color: #f5f5f5;
}
.targetContainer {
    width: 100%;
    height: 40vh;
    display: flex;
}
.methodList {
    width: 50%;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.detailMethod {
    width: 50%;
    heigth: 100%;
    padding: 0.5rem 1rem;
}
.custom-col {
    max-width: 22%; /* 너비를 줄임 */
    flex: 0 0 22%; /* Bootstrap flex-basis를 수정 */
}
.analysisButton {
    display: flex;
    flex-direction: column;
}
.buttonStyle {
    font-size: 0.7rem;
}
.iconTag {
    font-size: 15px;
}

.saveButton {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.nomalButton {
    height: 100%;
    width: 100%;
}

.container {
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
}
.row {
    margin: 1px 0px !important;
    padding: 0 !important;
}
.col-md-4,
.col-md-6,
.col-lg-2,
.col-lg-3 {
    padding: 2px !important;
}

.settingButtonGroup {
    padding: 0 !important;
}
.point-list-ctn {
    width: 100%;
    height: 130px;
    overflow-y: auto;
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 0.5rem;
    background-color: white;
}
.disabled-select {
    cursor: not-allowed;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #f3f3f3;
    border-color: #ccc;
}
/* 여기부터 */
.saveButton .button-group-vertical {
    display: flex;
    flex-direction: column;
    gap: 0.3rem; /* 버튼 간격 */
}

.saveButton .btn {
    font-size: 0.9rem; /* 버튼 텍스트 크기 조정 */
    padding: 0.5rem 1rem; /* 버튼 안쪽 여백 */
    text-align: center;
}
.icon-ctn {
    width: 5rem;
    height: 5rem;
    background-color: #eceff1;
    cursor: pointer;
    font-size: 2rem;
    color: #455a64;
    border-radius: 5px;
    border: 1px solid #cfd8dc;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
}

.icon-ctn:hover {
    color: #eceff1;
    background-color: #455a64;
}

.btn-square {
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
}
/* 여기까지 */
/* .icon-ctn {
    width: 100%;
    height: 100%;
    background-color: #eceff1;
    cursor: pointer;
    font-size: 2rem;
    color: #455a64;
    border-radius: 5px;
    border-left: solid 1px #cfd8dc;
    border-top: solid 1px #cfd8dc;
    border-right: solid 1px #90a4ae;
    border-bottom: solid 1px #90a4ae;
    transition: background-color 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
} */
/* .icon-ctn:hover {
    color: #eceff1;
    background-color: #455a64;
} */
</style>

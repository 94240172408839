<template>
    <div style="width: 100%; margin: 10px 0px 0px 0px !important;">
        <DataTable
            id="result-data-table"
            ref="result-data-table"
            class="p-datatable-sm data-table-container"
            :value="tableDatas"
            editMode="row"
            :dataKey="keyField"
            :editingRows.sync="editingRows"
            showGridlines
            :selection.sync="selected"
            @row-edit-save="onRowEditSave"
            @row-reorder="onRowReorder"
        >
            <template #empty>
                <div class="row m-0 justify-content-center">
                    <span class="m-0" style="font-size: 13px; font-weight: bold;">
                        {{ "표시할 데이터가 없습니다." }}
                    </span>
                </div>
            </template>
            <template #groupheader="slotProps">
                <span style="font-weight: bold;">
                    {{ slotProps.data.category }}
                </span>
            </template>
            <template #header>
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div>
                        <span class="m-0 table-title">{{ viewTitle }}</span>
                    </div>
                    <div style="display: flex;">
                        <!-- <div class="mr-2" style="display: flex; align-items: center;">
                            <span class="mr-2" style="font-size: 12px; font-weight: 100;">{{ "표시 항목 : " }}</span>
                            <MultiSelect
                                :value="tableColumns"
                                :options="columns"
                                optionLabel="header"
                                @input="onToggle"
                                placeholder="Select Columns"
                                style="width: 10em; height: 34px; font-size: 13px;"
                            />
                        </div> -->
                        <div v-if="!isEmpty(controls)" class="btn-group">
                            <button
                                v-for="(control, index) in controls"
                                :key="index"
                                type="button"
                                :class="'btn btn-sm ' + control.class"
                                :disabled="!isEditMode"
                                @click="handleButtonClick(control, index)"
                            >
                                {{ control.label }}
                            </button>
                        </div>
                        <div v-for="(control, index) in controls" :key="`file-input-${index}`">
                            <input
                                v-if="control.event == 'import'"
                                type="file"
                                :ref="'fileInput-' + index"
                                accept=".xls,.xlsx,.csv"
                                style="display: none;"
                                @change="importExcel"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <!-- row 순서 이동 버튼 -->
            <Column
                v-if="isEditMode"
                :rowReorder="true"
                :reorderableColumn="false"
                :styles="{ width: '2%' }"
                :bodyStyle="{ 'text-align': 'center' }"
            />
            <!-- 편집 버튼 -->
            <Column
                v-if="isEditMode"
                :rowEditor="true"
                :reorderableColumn="false"
                :styles="{ width: '2%' }"
                :bodyStyle="{ 'text-align': 'center' }"
            />
            <!-- 선택 버튼 -->
            <Column
                v-if="isEditMode"
                selectionMode="multiple"
                :reorderableColumn="false"
                :exportable="false"
                :styles="{ width: '2%' }"
                :bodyStyle="{ 'text-align': 'center' }"
            />
            <!-- table-data-row -->
            <Column
                v-for="col in tableColumns"
                :key="col.field"
                :field="col.field"
                :header="col.header"
                :reorderableColumn="false"
                :styles="{ width: `${col.width ?? ''}` }"
                :bodyStyle="{ 'text-align': 'center', 'font-size': '13px', 'font-weight': 'bold' }"
            >
                <!-- 테이블 내용 -->
                <template #body="{ data }">
                    <div v-if="toggleFields == col.field">
                        <!-- {{ data }} -->
                        <b-form-checkbox
                            switch
                            size="md"
                            v-model="data[col.field]"
                            @change="handleToggle(data[keyField], col.field, data[col.field])"
                            :disabled="!isEditMode"
                        >
                        </b-form-checkbox>
                    </div>
                    <div v-else>
                        {{ data[col.field] }}
                    </div>
                </template>
                <!-- 테이블 편집시 각 필드 편집 유형 ex) input, select, date-picker 등 -->
                <template v-if="isEditMode" #editor="slotProps">
                    <date-picker
                        v-if="slotProps.column.field == 'regDt'"
                        ref="datePicker"
                        v-model="slotProps.data[slotProps.column.field]"
                        :type="'date'"
                        :range="false"
                        :valueType="'YYYY-MM-DD'"
                        lang="ko"
                        :placeholder="'일자'"
                        :format="'YYYY-MM-DD'"
                    ></date-picker>
                    <InputText
                        v-else
                        v-model="slotProps.data[slotProps.column.field]"
                        style="width: 100%; font-size: 0.8rem;"
                        autofocus
                    />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import * as XLSX from "xlsx";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
// import MultiSelect from "primevue/multiselect";
import DatePicker from "vue2-datepicker";

export default {
    props: {
        /**
         * primevue data table 은 column, row data 의 형식이 일치 해야함.
         * 이 컴포넌트를 사용하는 쪽에서 형식에 맞춰 구성해서 넘겨줘야함.
         */
        columns: { type: Array, default: () => [] }, // primevue DataTable 컬럼 형식
        rows: { type: Array, default: () => [] }, // Row-Data
        title: { type: String, default: "" },
        controls: { type: Array, default: () => [] }, // Table Header Control Buttons
        toggleFields: { type: Array, default: () => [] }, // status 필드 리스트
        mode: { type: String, default: "view" }, // Edit Mode (view/edit/new)
        keyField: { type: String, default: "" },
        isExcelData: { type: Boolean, default: false }, // 테이블 데이터 엑셀 export, import 버튼 활성화 여부
    },
    components: {
        DataTable,
        Column,
        InputText,
        // MultiSelect,
        DatePicker,
    },
    data() {
        return {
            tableDatas: [],
            tableColumns: [],
            editingRows: [],
            selected: null,
            filters: {},
        };
    },
    computed: {
        viewTitle() {
            return this.title;
        },
        isEditMode() {
            return this.mode !== "view";
        },
    },
    watch: {},
    async created() {
        /** sort, edit 시 데이터 변경이 일어나기 때문에 props 로 받은 col, row 를 변수에 담음 */
        this.tableDatas = this.rows;
        this.tableColumns = this.columns;
    },
    mounted() {},
    methods: {
        // table header button control function
        handleButtonClick(control, index) {
            if (control.event == "import") this.triggerFileInput(index);
            else this.$emit("button-click", control.event, control.options);
        },
        triggerFileInput(index) {
            const fileInput = this.$refs[`fileInput-${index}`];
            if (fileInput) {
                fileInput[0].click(); // 배열로 반환되므로 [0] 사용
            }
        },
        importExcel(event) {
            const file = event.target.files[0];
            if (!file) return;

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array" });

                // 첫 번째 시트를 가져옴
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                // 시트 데이터를 JSON
                const jsonData = XLSX.utils.sheet_to_json(sheet);
                const jsonDataKeyFormat = jsonData.map((item, index) => {
                    let dynamicKeys = {};
                    for (let key in item) {
                        if (key.startsWith("조정")) {
                            dynamicKeys[`adjust${key.replace("조정", "")}`] = item[key];
                        }
                    }
                    return {
                        idx: this.tableDatas.length + index + 1,
                        regDt: this.convertExcelDateToISO(item["일자"]),
                        originData: item["원본값"],
                        ...dynamicKeys, // 조정 변수
                        sumAdjust: item["조정 합계"],
                        adjustValue: item["조정값"],
                    };
                });
                console.log("jsonDataKeyFormat", jsonDataKeyFormat);
                this.tableDatas.push(...jsonDataKeyFormat);
            };
            reader.readAsArrayBuffer(file);

            event.target.value = null;
        },
        convertExcelDateToISO(serial) {
            // Excel 날짜 기준: 1900년 1월 1일
            let excelStartDate = new Date(1900, 0, 1);
            // Excel 날짜는 1일부터 시작하고 1900년 2월 29일(가상의 날짜)로 인해 1일을 추가로 빼줍니다.
            let jsDate = new Date(excelStartDate.getTime() + (serial - 2) * 24 * 60 * 60 * 1000);
            // ISO 날짜 형식 (YYYY-MM-DD)
            return jsDate.toISOString().split("T")[0];
        },
        // data status change function
        handleToggle(key, field, status) {
            status = status === true ? "Y" : "N";
            this.$emit("toggle-changed", key, field, status);
        },
        onRowEditSave(event) {
            let { newData, index } = event;

            this.tableDatas[index] = newData;
        },
        onRowReorder(event) {
            this.tableDatas = event.value;
        },
        onToggle(value) {
            this.tableColumns = this.columns.filter((col) => value.includes(col));
        },
        excelExport() {
            this.$refs["result-data-table"].exportCSV({ fileName: "my-data.csv" });
        },
        // excelExport() {
        //     var excelName = "excel_download.csv";
        //     var excelData = XLSX.utils.table_to_sheet(document.getElementById("result-data-table")); // table id를 넣어주면된다
        //     var workBook = XLSX.utils.book_new(); // 새 시트 생성

        //     XLSX.utils.book_append_sheet(workBook, excelData, excelName); // 시트 명명, 데이터 지정
        //     XLSX.writeFile(workBook, excelName); // 엑셀파일 만듬
        // },
    },
};
</script>

<style scoped>
.data-table-container {
    width: 100%;
    font-size: 0.9rem;
    margin-bottom: 0px !important;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.data-collapse-content {
    /* height: 83%; */
    width: 100%;
    padding: 0.5rem;
    /* justify-content: center;
    align-items: center; */
    /* overflow-y: scroll; */
    border: solid #efefef 1px;
    border-radius: 0px 0px 6px 6px;
    margin-bottom: 1rem;
    /* padding: 1rem 2rem; */
}
.table-title {
    font-weight: 600;
    font-size: 12px;
}
.custom-filter-input {
    height: 34px !important;
    font-size: 12px !important;
}
</style>

<template>
    <div class="row m-0">
        <!-- :transCodes="transCodes" -->
        <!-- @row-click="handleItemClick" 변수 수정 모달 구현 완료시 적용 -->
        <TableList2
            ref="data-table-list"
            :columns="columns"
            :rows="mainPropDataList"
            :title="'주 변수 데이터'"
            :keyField="'idx'"
            :mode="'edit'"
            :controls="controls"
            @button-click="handleListButtonClick"
        />
    </div>
</template>

<script>
import TableList2 from "@src/views/component/v2.1/ListDetailView/TableList2.vue";

export default {
    props: [],
    components: {
        TableList2,
    },
    data() {
        return {
            controls: [
                { event: "export", label: "내보내기", class: "btn-primary" },
                { event: "import", label: "불러오기", class: "btn-danger" },
            ],
            columns: [
                {
                    field: "regDt",
                    header: "일자",
                    // width: "10%",
                },
                {
                    field: "originData",
                    header: "원본값",
                    // width: "30%",
                },
                // {
                //     field: "adjust1",
                //     header: "조정1",
                // },
                // {
                //     field: "adjust2",
                //     header: "조정2",
                // },
                {
                    field: "sumAdjust",
                    header: "조정 합계",
                    // width: "16%",
                },
                {
                    field: "adjustValue",
                    header: "조정값",
                    // width: "16%",
                },
            ],
            mainPropDataList: [
                {
                    idx: 1,
                    regDt: "2024-12-04",
                    originData: 123,
                    // adjust1: 1,
                    // adjust2: 2,
                    sumAdjust: 0,
                    adjustValue: 123,
                },
            ],
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        async handleListButtonClick(event, options) {
            switch (event) {
                case "export":
                    await this.exportData();
                    break;
                case "import":
                    await this.importData();
                    break;
                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async exportData() {
            console.log("exportData");
            this.$refs["data-table-list"].excelExport();
        },
        importData() {
            console.log("importData");
        },
    },
};
</script>

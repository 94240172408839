<template>
    <div class="row m-0">
        <!-- :transCodes="transCodes" -->
        <!-- @row-click="handleItemClick" 변수 수정 모달 구현 완료시 적용 -->
        <TableList
            ref="table-list"
            :title="'데이터 조정'"
            :columns="columns"
            :rows="adjustList"
            :keyField="'idx'"
            :useFilter="false"
            :fixedHeader="false"
            :controls="controls"
            :mode="'edit'"
            :useMaxHeight="true"
            :customMaxHeight="'20vh'"
            :isSort="false"
            @button-click="handleListButtonClick"
        />
    </div>
</template>

<script>
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";

export default {
    props: [],
    components: {
        TableList,
    },
    data() {
        return {
            controls: [
                { event: "regist", label: "추가", class: "btn-primary" },
                { event: "delete", label: "삭제", class: "btn-danger" },
            ],
            transCodes: [
                {
                    field: "unit",
                    codes: this.$store.state.units,
                },
            ],
            columns: [
                {
                    label: this.$t("데이터 조정"),
                    field: "adjustName",
                    type: "text",
                },
                {
                    label: this.$t("조정 사유"),
                    field: "adjustReason",
                    type: "text",
                },
                {
                    label: this.$t("비고"),
                    field: "description",
                    type: "text",
                },
            ],
            adjustList: [
                {
                    idx: 1,
                    adjustName: "adjust data 1",
                    adjustReason: "시설 공사(YYYY-MM-DD) 전기 사용량 제외",
                    description: null,
                },
                {
                    idx: 2,
                    adjustName: "adjust data 1",
                    adjustReason: "시설 공사(YYYY-MM-DD) 전기 사용량 제외",
                    description: null,
                },
                {
                    idx: 3,
                    adjustName: "adjust data 1",
                    adjustReason: "시설 공사(YYYY-MM-DD) 전기 사용량 제외",
                    description: null,
                },
                {
                    idx: 4,
                    adjustName: "adjust data 1",
                    adjustReason: "시설 공사(YYYY-MM-DD) 전기 사용량 제외",
                    description: null,
                },
                {
                    idx: 5,
                    adjustName: "adjust data 1",
                    adjustReason: "시설 공사(YYYY-MM-DD) 전기 사용량 제외",
                    description: null,
                },
                {
                    idx: 6,
                    adjustName: "adjust data 1",
                    adjustReason: "시설 공사(YYYY-MM-DD) 전기 사용량 제외",
                    description: null,
                },
                {
                    idx: 7,
                    adjustName: "adjust data 1",
                    adjustReason: "시설 공사(YYYY-MM-DD) 전기 사용량 제외",
                    description: null,
                },
            ],
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        async handleListButtonClick(event, options) {
            switch (event) {
                case "regist":
                    await this.registNew();
                    break;
                case "delete":
                    await this.deleteChecked();
                    break;
                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async registNew() {
            console.log("registNew");
        },
        async deleteChecked() {
            console.log("deleteChecked");
        },
    },
};
</script>
